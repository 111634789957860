import React from "react"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { GeneralForm } from "@components/Form"
import { splitText } from "@utils"

const WhitepaperDownloadPage = ({ data }) => {
  const { themeColor } = usePageSetup({ themeColor: "bg-teal-light", displayGeneralForm: false })

  const unlockContent = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    window.open(
      "https://www.jkdesign.com/wp/wp-content/uploads/2020/06/jk-whitepaper-navigating-whats-next.pdf",
      "_blank"
    )
  }

  return (
    <Layout>
      <Seo title="Whitepaper Download" description="" />
      <section className={themeColor}>
        <Hero>
          <div className="grid-cols-10 xl:grid">
            <div className="col-span-8">
              <Hero.Title>
                <h1
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-white": "Instantly access our",
                      "text-gray-darkest": "nimble approach to the “new normal.”",
                    }),
                  }}
                />
              </Hero.Title>
            </div>
          </div>
        </Hero>
      </section>
      <main>
        <section className="pt-clamp-18-35">
          <div className="container">
            <div className="mx-auto lg:w-10/12">
              <div className="grid-cols-10 lg:grid">
                <div className="col-span-8">
                  <p>
                    This whitepaper provides a practical framework for nimbly adapting your marketing plans and
                    messaging to the emerging realities of your marketplace.
                  </p>
                  <h3 className="text-clamp-26-32 mt-15 text-teal">Please fill in the form below to access:</h3>
                  <ul className="mt-10">
                    <li>A streamlined process for nimbly adapting to the new realities of your marketplace</li>
                    <li>
                      Areas in your marketing and communications where you can create opportunities now and into the
                      future
                    </li>
                    <li>Engagement trends that are shaping the “new normal”</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-24">
            <div className="mx-auto xl:w-10/12">
              <GeneralForm
                subject="Whitepaper Download"
                recipient="katiek@jkdesign.com, markm@jkdesign.com, johng@jkdesign.com"
                fn={unlockContent}
              />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default WhitepaperDownloadPage
